<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="61"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/background.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/restaurant.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/restaurant.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/school.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/school.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/supermarket.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/supermarket.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/zoo.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/zoo.svg"),
          id: 4,
        },
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/restaurant-story.svg"),
          id: 1,
          centencePinyin: "Tā Xīngqīyī qù xuéxiào.",
          sentenceHanzi: "他星期一去学校。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/school-story.svg"),
          id: 2,
          centencePinyin: "Bàba Xīngqīliù qù chāoshì.",
          sentenceHanzi: "爸爸星期六去超市。",

        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/supermarket-story.svg"),
          id: 3,
          centencePinyin: "Tāmen hé lǎoshī Xīngqīsān qù dòngwùyuán.",
          sentenceHanzi: "他们和老师星期三去动物园。",
         
        },
         {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-61/zoo-story.svg"),
          id: 4,
          centencePinyin: "Tāmen Xīngqīwǔ qù fàndiàn.",
          sentenceHanzi: "他们星期五去饭店。",
      
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











