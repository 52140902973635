<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" 
    :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "xīngqī",
          hanzi: "星期",
          english: "week",
          image: require(`@/assets/img/12-Vocabulary/lesson-61-xingqi-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "tāmen",
          hanzi: "他们",
          english: "they",
          image: require(`@/assets/img/12-Vocabulary/lesson-61-tamen-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "míngtiān",
          hanzi: "明天",
          english: "tomorrow",
          image: require(`@/assets/img/12-Vocabulary/lesson-61-mingtian-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "dòngwùyuán",
          hanzi: "动物园",
          english: "zoo",
          image: require(`@/assets/img/12-Vocabulary/lesson-61-dongwuyuan-bubble.svg`),
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
