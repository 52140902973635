<template>
  <div class="game-container">
    <FindElementGame :bgImg="bgImg" :imgList="imgList" :lessonNo="61" />
  </div>
</template>

<script>
import FindElementGame from "@/components/Course/GamePage/FindElementGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-61-1/background.svg"),
      imgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-61-1/date-1.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-61-1/date-3.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-61-1/date-9.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-61-1/date-20.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-61-1/date-31.svg"),
        },
     
      ],
      // titleInfo:{
      //   pinyin:"Nǐ hǎo! Nǐ chī shénme?",
      //   hanzi:"你好! 你吃什么?"
      // }
    };
  },
  components: {
    FindElementGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>