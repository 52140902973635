var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("DragToDressGame", {
        attrs: {
          bgImgList: _vm.bgImgList,
          bgImg: _vm.bgImg,
          optionImgList: _vm.optionImgList,
          lessonNO: 611,
          hasSentence: false,
          hasStar: false,
          titleInfo: _vm.titleInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }