<template>
  <div class="game-container">
    <RecognitionPinyinGame
      :optionList="optionList"
      :imgList="imgList"
      :isFullScreen="true"
    />
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      // titleInfo: {
      //   pinyin: "…… shì  nǎ guó rén?",
      //   hanzi: "……是哪国人？",
      // },
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-61/image-1-he.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-61/image-2-she.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-61/image-3-they.svg"),
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-61/image-4-he.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-61/image-5-she.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-61/image-6-they.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-61/image-7-they.svg"),
        },
       
      ],

      imgList: [
        {
          id: 1,
          pinying: "tā",
          hanzi: "他",
        },
        {
          id: 2,
          pinying: "tāmen",
          hanzi: "他们",
        },
        {
          id: 3,
          pinying: "tā",
          hanzi: "她",
        },
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











