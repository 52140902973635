<template>
  <div class="game-container">
    <DragToDressGame
      :bgImgList="bgImgList"
      :bgImg="bgImg"
      :optionImgList="optionImgList"
      :lessonNO="611"
      :hasSentence="false"
      :hasStar="false"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import DragToDressGame from "@/components/Course/GamePage/DragToDressGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/background-1.svg"),
      titleInfo:{
        pinyin:"xīngqī …",
        hanzi:"星期……"
      },
      bgImgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqier-done.svg"),
          id: 1,
          index: 1,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqisan-done.svg"),
          id: 2,
          index: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqisi-done.svg"),
          id: 3,
          index: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqiwu-done.svg"),
          id: 4,
          index: 4,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqiliu-done.svg"),
          id: 5,
          index: 5,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqitian-done.svg"),
          id: 6,
          index: 6,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/date-8-done.svg"),
          id: 7,
          index: 7,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/date-19-done.svg"),
          id: 8,
          index: 8,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/date-24-done.svg"),
          id: 9,
          index: 9,
        },
      ],
      optionImgList: [
        {
          id: 1,
          index: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqier.svg"),
        },
        {
          id: 2,
          index: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqisan.svg"),
        },
        {
          id: 3,
          index: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqisi.svg"),
        },
        {
          id: 4,
          index: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqiwu.svg"),
        },
        {
          id: 5,
          index: 5,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqiliu.svg"),
        },
        {
          id: 6,
          index: 6,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/xingqitian.svg"),
        },
        {
          id: 7,
          index: 7,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/date-8.svg"),
        },
        {
          id: 8,
          index: 8,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/date-19.svg"),
        },
        {
          id: 9,
          index: 9,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-1/date-24.svg"),
        },
      ],
    };
  },
  components: {
    DragToDressGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











