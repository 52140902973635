<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "xīngqī",
              hanzi: "星期",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "tā mén",
              hanzi: "他们",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "míngtiān",
              hanzi: "明天",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "dòngwùyuán",
              hanzi: "动物园",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
           
            //、、和、姐姐、弟弟

            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我会用",
          isUse: true,
          contentList: [
            [
              {
                pinyin: "zhīshídiǎn",
                hanzi: "知识点",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
             
              {
                pinyin: "rì qī",
                hanzi: "日期",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "zhōng",
                hanzi: "中",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "guān yú",
                hanzi: "关于",
                symbol: "",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "yī zhōu",
                hanzi: "一周",
                symbol: "",
                group: 1,
                line: 1,
                index: 6,
                symbolType: 0,
              },
              {
                pinyin: "qī tiān",
                hanzi: "七天",
                symbol: "",
                group: 1,
                line: 1,
                index: 7,
                symbolType: 0,
              },
              {
                pinyin: "dē",
                hanzi: "的",
                symbol: "",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 0,
              },
              {
                pinyin: "biǎo dá",
                hanzi: "表达",
                symbol: "",
                group: 1,
                line: 1,
                index: 9,
                symbolType: 0,
              },
            ],
            [
              {
                pinyin: "rú",
                hanzi: "如",
                symbol: "",
                group: 1,
                line: 2,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 2,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "xīngqī yī",
                hanzi: "星期一/",
                symbol: "",
                group: 1,
                line: 2,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "èr",
                hanzi: "二/",
                symbol: "",
                group: 1,
                line: 2,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "sān",
                hanzi: "三/",
                symbol: "",
                group: 1,
                line: 2,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "sì",
                hanzi: "四/",
                symbol: "",
                group: 1,
                line: 2,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "wǔ",
                hanzi: "五/",
                symbol: "",
                group: 1,
                line: 1,
                index: 6,
                symbolType: 0,
              },
              {
                pinyin: "liù",
                hanzi: "六/",
                symbol: "",
                group: 1,
                line: 1,
                index: 7,
                symbolType: 0,
              },
              {
                pinyin: "tiān",
                hanzi: "天",
                symbol: "",
                group: 1,
                line: 1,
                index: 7,
                symbolType: 0,
              },
              {
                pinyin: "",
                name: "",
                symbol: "。",
                group: 1,
                line: 2,
                index: 8,
                symbolType: 6,
              },
            ],
          ],
        },
        {
          title: "我会说",
          isUse: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "yòng",
              hanzi: "用",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "Zhōngwén",
              hanzi: "中文",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "jiǎn dān ",
              hanzi: "简单",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "dē",
              hanzi: "地",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "miáo shù ",
              hanzi: "描述",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "rì chéng",
              hanzi: "日程",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            },
           
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>