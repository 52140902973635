<template>
  <div class="game-container">
    <DragToCakeGame
      :bgImgList="bgImgList"
      :bgImg="bgImg"
      :optionImgList="optionImgList"
      :lessonNO="61"
      :hasStar="false"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import DragToCakeGame from "@/components/Course/GamePage/DragToCakeGame";
export default {
  data() {
    return {
      titleInfo: {
        hanzi: "我的动物园",
        pinyin: "wǒ de dòngwùyuán",
      },
      bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/background.svg"),
      bgImgList: [
        {
          id: 1,
          group:1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/elephant-done.svg"),
        },
        {
          id: 2,
          group:2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/giraffe-done.svg"),
        },
        {
          id: 3,
          group:3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/lion-done.svg"),
        },
        {
          id: 4,
          group:4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/monkey-done.svg"),
        },
        {
          id: 5,
          group:5,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/panda-done.svg"),
        },
        {
          id: 6,
          group:6,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/rhino-done.svg"),
        },
        {
          id: 7,
          group:7,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/whale-done.svg"),
        },
      ],
      optionImgList: [
        {
          id: 1,
          group: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/elephant.svg"),
        },
        {
          id: 2,
          group: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/giraffe.svg"),
        },
        {
          id: 3,
          group: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/lion.svg"),
        },
        {
          id: 4,
          group: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/monkey.svg"),
        },
        {
          id: 5,
          group: 5,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/panda.svg"),
        },
        {
          id: 6,
          group: 6,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/rhino.svg"),
        },
        {
          id: 7,
          group: 7,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-61-2/whale.svg"),
        },
      ],
    };
  },
  components: {
    DragToCakeGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
